/* You can add global styles to this file, and also import other style files */
@import url("remixicon/fonts/remixicon.css");
@import './mobile.scss';

html,
body {
  height: 100%;
}

a {
  text-decoration: unset;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
}

@mixin font($fontSize, $lineHeight, $fontWeight, $color) {
  font-size: $fontSize !important;
  line-height: $lineHeight !important;
  font-weight: $fontWeight !important;
  color: $color !important;
}

.page-header {
  padding: 15px 20px;
  background-color: #efeff4;
}

.heading-1 {
  @include font(18px, 24px, bold, #363636);
  margin-bottom: 0 !important;
}

.heading-2 {
  @include font(18px, 16px, normal, #3a3a3a);
  margin-bottom: 0 !important;
}

.mt-3 {
  margin-top: 3px !important;
}

.mt-1 {
  margin-top: 1px !important;
}

.arrow-hover-btn {
  display: inline-flex;
  position: relative;
  cursor: pointer;
  height: 30px;
  align-items: center;
  justify-content: center;
  padding: 5px 30px 5px 10px;
  box-sizing: border-box;
  margin-bottom: 5px;
  border-radius: 14px;
  @include font(12px, 16px, bold, #3c3c3c);

  i {
    display: none;
    position: absolute;
    right: 9px;
    top: 7px;
  }

  &:hover {
    background-color: #e3f1ff;

    i {
      display: block;
    }
  }
}

.text-red {
  color: #d9232d !important;
}

.arrow-back-btn {
  font-size: 24px;
  padding: 5px;
  color: #000000;
  cursor: pointer;
  width: 24px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: rgb(0 0 0 / 8%);
  }
}

.showTooltipOnCondition {
  visibility: visible !important;
  display: inline-flex !important;
}

.tooltipOnCondition.atom-tooltiptext {
  visibility: hidden;
  display: none;
  flex-wrap: wrap;
  width: 120px;
  background-color: #e6f3ff;
  color: #000;
  padding: 8px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  left: -37px;
  top: 10px;
  font-size: 12px;
  line-height: 16px;
  box-shadow: 0px 2px 8px #00000029;
  border: 1px solid #cccccc;
  text-align: left;

  &.detail-tooltip {
    min-width: 270px;
  }

  h2 {
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  p {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    margin-bottom: 0px;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    width: 10px;
    height: 10px;
    background: #e6f3ff;
    left: 56px;
    transform: rotate(45deg);
    top: -5px;
  }

  &.tooltip-bottom {
    &::before {
      left: 56px;
      transform: rotate(45deg);
      top: -5px;
      border-left: 1px solid #cccccc;
      border-top: 1px solid #cccccc;
    }
  }

  &.tooltip-right {
    left: 33px;
    top: -14px;

    &::before {
      left: -6px;
      transform: rotate(312deg);
      top: 17px;
      box-shadow: -3px -2px 8px -2px rgba(0, 0, 0, 0.5);
    }
  }

  &.tooltip-left {
    top: -14px;
    right: 33px;
    left: unset;

    &::before {
      top: 18px;
      box-shadow: -3px -2px 8px -2px rgba(0, 0, 0, 0.5);
      left: unset;
      right: -5px;
      transform: rotate(130deg);
    }
  }

  &.tooltip-top {
    right: -58px;
    left: unset;
    top: 29px;
    bottom: unset;

    &::before {
      top: -5px;
      box-shadow: -2px -3px 9px -2px rgba(0, 0, 0, 0.5);
      left: unset;
      right: 64px;
      transform: rotate(47deg);
    }
  }
}

.more-btn {
  cursor: pointer;
  padding: 0px;

  .items-count {
    display: inline-block;
    margin-right: 3px;
  }

  i {
    margin-left: 3px;
    font-size: 16px;
  }

  background-color: white;
  display: inline-flex;
  align-items: center;
  @include font(12px, 16px, bold, #3c3c3c);
  border: unset;
}

.floating-table-label {
  position: absolute;
  top: 7px;
  left: 32%;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  z-index: 114;
}

html {
  .mat-table-theme {
    tbody {
      tr {
        td {
          vertical-align: middle !important;
        }

        td,
        p,
        .note {
          font-size: 14px !important;
          line-height: 16px !important;
        }

        p {
          margin-bottom: 0px !important;
        }
      }
    }

    th {
      min-width: unset;
    }
  }
}

@media only screen and (max-width: 1366px) {
  html {
    .mat-table-theme {
      tbody {
        tr {

          td,
          p,
          .note,
          .arrow-hover-btn {
            font-size: 12px !important;
            line-height: 16px !important;
          }
        }
      }
    }
  }
}

.border-box {
  box-sizing: border-box !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.chart-section {
  width: calc(100% - 272px);
}

.date-selection-section {
  padding: 15px 20px;

  margin-bottom: 15px;

  .today-selection {
    font-size: 14px;
    line-height: 16px;
    color: #086ac9;
    font-weight: 500;
    cursor: pointer;
  }

  .single-date-changer {
    display: flex;
    align-items: center;

    span {
      white-space: nowrap;
      font-size: 14px;
      color: #3a3a3a;
    }

    a {
      padding: 5px;
      font-size: 20px;
      cursor: pointer;
      border-radius: 4px;

      &:hover {
        background-color: rgb(167 152 152 / 12%);
      }

      &.arrow-left {
        margin-right: 10px;
      }

      &.arrow-right {
        margin-left: 10px;
      }
    }
  }
}

.modal-header-border {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.meeting-list-group {
  h2 {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 10px;
  }

  ul {
    list-style: none;
    padding-left: 0px;
    border-top: 1px solid #c4c4c4;
    padding-top: 25px;
    margin-top: 0;

    li {
      position: relative;
      cursor: pointer;
      padding-bottom: 15px;
      border-bottom: 1px solid #c4c4c4;
      margin-bottom: 25px;
      padding-left: 20px;
      padding-right: 20px;

      h3 {
        font-size: 14px;
        white-space: pre;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 3px;
        line-height: 15px;
        color: #272727;
      }

      p {
        font-size: 12px;
        white-space: pre;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 14px;
        color: #6f6f6f;
        margin-bottom: 3px;
      }

      i.list-dot {
        position: absolute;
        left: -2px;
        top: -5px;
        color: #6F6F6F;

        &::after {
          content: "";
          position: absolute;
          height: 47px;
          border-left: 1px dashed #6F6F6F;
          width: 2px;
          left: 6px;
          top: 16px;

        }
      }

      i.arrow-right {
        position: absolute;
        right: 0px;
        top: 25%;
        font-size: 20px;
      }

      .meeting-time {
        font-size: 12px;
        line-height: 15px;
        font-weight: 400;
      }
    }
  }
}
.text-center{
 text-align: center !important; 
}
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  position: relative;
  flex-direction: column;
  .showing-result{
  color: #3A3A3A;
   font-size: 12px;
   line-height: 16px;
   position: relative;
   bottom: 6px;
  }
  &.border-left{
    &::before{
      content: '';
      border-left: 1px solid #d7d7dc;
      height: 36px;
      width: 2px;
      left: -10px;
      position: absolute;
    }
  }
  .pagination-navigation{
   font-size: 24px;
   color: #3C3C3C;
   padding: 2px;
   margin-right: 5px;
   cursor: pointer !important;
   &[disabled]{
    color: #C4C4C4 !important;
    cursor: not-allowed;
   }
  }
  ul {
    list-style: none;
    padding: 0;
    display: flex;
   margin: 0;
    li {
      a {
        cursor: pointer;
        color: #3C3C3C;
        float: left;
        text-decoration: none;
        transition: background-color 0.3s;
        margin: 0 4px;
        border-radius: 4px;
        height: 21px;
        width: fit-content;
        min-width: 21px;
        padding: 0 2px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
         justify-content: center;
         font-size: 12px;
         line-height: 16px;
         font-weight: 400;
        &.active {
          background-color: #3C3C3C;
          color: white;
        }

        &:hover:not(.active) {
          background-color: #ddd;
        }
      }
    }
  }
}


.input-group {
  ::ng-deep mat-label{
      font-weight: 500px !important;
  }
}
.mat-calendar-body-cell {
  &:hover {
    .mat-calendar-body-cell-content {
      background-color: #dbedff !important;
    }
  }
  // .mat-calendar-body-cell-content {
  //   background-color: #dbedff !important;
  // }
  &.mat-calendar-body-active{
    .mat-calendar-body-cell-content{
      background-color: #dbedff !important;
    }
  }
}
.mat-calendar-body-in-range::before {
  background: #DBEDFF !important;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #086ac9 !important;
  color: #fff;
}
.br-b-8{
  border-radius: 0 0 8px 8px !important;
}
.input-group{
  mat-label{
    font-weight: 500 !important;
    color: #3c3c3c !important;
  }
}
button{
  box-shadow: none !important;
}
.link{
  color: #086AC9 !important;
}
.pb-4{
  padding-bottom: 4px;
}

.upperLayout{
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #efeff4ed;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  h3{
    font-size: 20px;
    margin: 0;
    color:#000000
  }
}

.mat-expansion-indicator::after {
  color: #3c3c3c !important;
}
.mobileCtas{
  display: none;
}

a:hover {
  color: #d7232d !important;
  background-color: transparent;
  text-decoration: underline;
}