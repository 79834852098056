@media only screen and (max-width: 768px) {
    .toggle-button{
        right: 0 !important;
    }
    .dashboardMobile{
        flex-direction: column;
        .w-50{
            width: 100% !important;
        }
    }
    .pageHeader {
        flex-direction: column;
        align-items: left !important;
        gap: 10px !important;
        align-items: baseline !important;
        position: relative !important;
        padding-top: 0px !important;

        .ctas {
            width: 100% !important;
            position: fixed !important;
            bottom: 0px !important;
            left: 0px !important;
            padding: 5px;
            border-top: 1px solid #D7D7DC;
            box-shadow: 0px -6px 8px 0px rgba(60, 60, 60, 0.10);
            background-color: #fff;
            z-index: 1;
            box-sizing: border-box;
            justify-content: space-between !important;

            button {
                white-space: nowrap !important;
                width: calc(50% - 5px);
                margin: 0px !important;
            }
        }

        .mobileCtas {
            display: flex !important;
            position: absolute;
            right: 0px;
            top: 0px;
            gap: 15px;

            button {
                min-width: unset !important;
                padding: 0;
                background-color: transparent;

                i {
                    font-size: 5px;
                    span{
                        font-family: "Roboto", "Helvetica Neue", sans-serif;
                    }
                }
                
            }
        }
    }

    // .expandable-strip{
    //     padding-bottom: 40px !important;
    // }
    .mobileHidden {
        display: none !important;
    }

    .w-25 {
        width: calc(50% - 10px) !important;
    }

    .form-container {
        padding: 10px !important;
    }
    .hamburger-sidebar {
        position: fixed;
        top: 5px;
        left: 0px !important;
        height: 29px;
        border-bottom: 2px solid rgba(0,0,0,.12) !important;
        width: 45px;
        /* box-shadow: 0px 10px 9px rgb(0 0 0 / 12%); */
    }
    .atom-header{
        border-bottom: 2px solid rgba(0,0,0,.12) !important;
        box-shadow: none !important;
        nav{
            &::after{
                box-shadow: none !important;
            }
        }
    }
    .boqListingWrap{
        height: unset !important;
    }
    .pagination.ml-auto {
        padding: 15px 0 10px !important;
        margin-left: 0 !important; 
    }
    .input-and-CTA{
        padding: 10px !important;
    }
    .mobile-flex-col{
        flex-direction: column !important;
        align-items: baseline !important;
        padding: 10px !important;
        mat-label{
            font-size: 12px !important;
            font-weight: 500 !important;
        }
        .mobile-margin{
            margin-left: 0 !important;
            margin-top: 5px !important;
        }
    }
    .touch-ui {
        display: none !important;
    }
    .atom-navbar-list {
        top: 55px !important;
        width: calc(100% - 30px) !important;
        border-radius: 8px;
        -webkit-box-shadow: 0px 5px 10px 0px rgba(191, 191, 191, 1);
        -moz-box-shadow: 0px 5px 10px 0px rgba(191, 191, 191, 1);
        box-shadow: 0px 5px 10px 0px rgba(191, 191, 191, 1);

        ul {
            li {
                border: none !important; 
            }
        }
    }

}

@media only screen and (min-width: 768px) {
    .desktopHidden {
        display: none !important;
    }
    
}