@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&family=Rubik:wght@300;400;500;600;700;800;900&display=swap");

/* Global Styles */
* {
  font-family: $roboto !important;
}

html {
  height: 100%;
}

body {
  font-family: $roboto !important;
  margin: 0px;
  height: 100%;
  box-sizing: border-box;
}

/* Typography - Font Sizes */
.text-xs {
  font-size: 12px !important;
  line-height: 16px !important;
}

.text-sm {
  font-size: 14px !important;
  line-height: 20px !important;
}

.text-base {
  font-size: 16px !important;
  line-height: 24px !important;
}

.text-lg {
  font-size: 18px !important;
  line-height: 28px !important;
}

/* Typography - Font Weights */
.font-300 {
  font-weight: 300 !important;
}

.font-400 {
  font-weight: 400 !important;
}

.font-500 {
  font-weight: 500 !important;
}

.font-600 {
  font-weight: 600 !important;
}

.font-700 {
  font-weight: 700 !important;
}

.font-800 {
  font-weight: 800 !important;
}

.font-900 {
  font-weight: 900 !important;
}

/* Position */
.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

/* Display */
.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.hidden {
  display: none !important;
}

/* Vertical Alignment */
.v-top {
  vertical-align: top;
}

.v-mid {
  vertical-align: middle;
}

/* Flexbox */
.flex-row {
  flex-direction: row !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-col {
  flex-direction: column !important;
}

.flex-col-reverse {
  flex-direction: column-reverse !important;
}

.justify-start {
  justify-content: flex-start !important;
}

.justify-end {
  justify-content: flex-end !important;
}

.justify-center {
  justify-content: center !important;
}

.justify-between {
  justify-content: space-between !important;
}

.justify-around {
  justify-content: space-around !important;
}

.justify-evenly {
  justify-content: space-evenly !important;
}

.items-start {
  align-items: flex-start !important;
}

.items-end {
  align-items: flex-end !important;
}

.items-center {
  align-items: center !important;
}

/* Width */
.w-auto {
  width: auto;
}

.w-50 {
  width: 50%;
}

.w-33 {
  width: 33.333333%;
}

.w-66 {
  width: 66.666667%;
}

.w-25 {
  width: 25%;
}

.w-75 {
  width: 75%;
}

.w-full {
  width: 100% !important;
}

.w-vw {
  width: 100vw !important;
}

.img-fluid {
  max-width: 100%;
  width: 100%;
}

/* Height */
.h-auto {
  height: auto;
}

.h-50 {
  height: 50%;
}

.h-33 {
  height: 33.333333%;
}

.h-66 {
  height: 66.666667%;
}

.h-25 {
  height: 25%;
}

.h-75 {
  height: 75%;
}

.h-full {
  height: 100%;
}

/* Text Alignment */
.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

/* Overflow */
.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

/* Padding 0 */
.p-0 {
  padding: 0px !important;
}

.px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.py-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}

.pt-0 {
  padding-top: 0px !important;
}

.pr-0 {
  padding-right: 0px;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.pl-0 {
  padding-left: 0px;
}

/* Padding 5 */
.p-5 {
  padding: 5px !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.pr-5 {
  padding-right: 5px !important;
}

.px-5 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.py-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

/* Padding 10 */
.p-10 {
  padding: 10px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.px-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.py-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

/* Padding 15 */
.p-15 {
  padding: 15px !important;
}

.pl-15 {
  padding-left: 15px !important;
}

.pr-15 {
  padding-right: 15px !important;
}

.px-15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.py-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

/* Padding 20 */
.p-20 {
  padding: 20px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.pr-20 {
  padding-right: 20px !important;
}

.px-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.py-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

/* Padding 30 */
.p-30 {
  padding: 30px !important;
}

.pl-30 {
  padding-left: 30px !important;
}

.pr-30 {
  padding-right: 30px !important;
}

.px-30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.py-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

/* Margin */
.mx-auto {
  margin: auto;
}

/* Margin 0 */
.ml-auto {
  margin-left: auto !important;
}

.m-0 {
  margin: 0px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.mx-0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.my-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

/* Margin 5 */
.m-5 {
  margin: 5px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mx-5 {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.my-5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

/* Margin 10 */
.m-10 {
  margin: 10px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mx-10 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.my-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

/* Margin 15 */
.m-15 {
  margin: 15px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.mx-15 {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.my-15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

/* Margin 20 */
.m-20 {
  margin: 20px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mx-20 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.my-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

/* Margin 30 */
.m-30 {
  margin: 30px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.mx-30 {
  margin-left: 30px !important;
  margin-right: 30px !important;
}

.my-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

/* Color */
.text-white {
  color: white;
}

.text-dark-black {
  color: $dark-black;
}

.text-black {
  color: $black !important;
}

.text-blue {
  color: $blue;
}

.text-red {
  color: $red;
}

.input-text {
  color: $input-black;
}

/* Bg color */
html .bg-current {
  background-color: $red !important;
}

html .bg-white {
  background-color: $white;
}

html .bg-dark-black {
  background-color: $dark-black !important;
}

html .bg-black {
  background-color: $black !important;
}

/* Radius */
.rounded-none {
  border-radius: 0px !important;
}

.rounded-sm {
  border-radius: 2px !important;
}

.rounded {
  border-radius: 4px !important;
}

.rounded-md {
  border-radius: 6px !important;
}

.rounded-lg {
  border-radius: 8px !important;
}

/* Border */
.input-border {
  border: 1px solid $lightGrey2 !important;
}

/* Box shadow */
.box-shadow-unset {
  box-shadow: unset !important;
}

/* Layout */
.atom-row {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  margin: 0px -10px;

  &.g-20 {
    margin: 0px -20px !important;

    [class*="atom-col-"] {
      padding: 0px 20px !important;
    }

    [class^="w-p"] {
      padding: 0px 20px !important;
    }
  }

  &.g-10 {
    margin: 0px -10px !important;

    [class*="atom-col-"] {
      padding: 0px 10px !important;
    }

    [class^="w-p"] {
      padding: 0px 10px !important;
    }
  }

  &.g-15 {
    margin: 0px -15px !important;

    &[class*="atom-col-"] {
      padding: 0px 15px !important;
    }

    [class^="w-p"] {
      padding: 0px 15px !important;
    }
  }

  &.g-5 {
    margin: 0px -5px !important;

    [class*="atom-col-"] {
      padding: 0px 5px !important;
    }

    [class^="w-p"] {
      padding: 0px 5px !important;
    }
  }

}

[class*="atom-col-"] {
  width: 100%;
  min-height: 1px;
  align-items: center;
  display: flex;
  padding: 0px 10px;
  box-sizing: border-box;

}

@media screen and (min-width: 960px) {
  .atom-col-sm-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .atom-col-sm-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .atom-col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .atom-col-sm-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .atom-col-sm-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .atom-col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .atom-col-sm-7 {
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .atom-col-sm-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .atom-col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .atom-col-sm-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .atom-col-sm-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .atom-col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  /* Offset */
  .atom-col-offset-sm-1 {
    margin-left: 8.33333%;
  }

  .atom-col-offset-sm-2 {
    margin-left: 16.66667%;
  }

  .atom-col-offset-sm-3 {
    margin-left: 25%;
  }

  .atom-col-offset-sm-4 {
    margin-left: 33.33333%;
  }

  .atom-col-offset-sm-5 {
    margin-left: 41.66667%;
  }

  .atom-col-offset-sm-6 {
    margin-left: 50%;
  }

  .atom-col-offset-sm-7 {
    margin-left: 58.33333%;
  }

  .atom-col-offset-sm-8 {
    margin-left: 66.66667%;
  }

  .atom-col-offset-sm-9 {
    margin-left: 75%;
  }

  .atom-col-offset-sm-10 {
    margin-left: 83.33333%;
  }

  .atom-col-offset-sm-11 {
    margin-left: 91.66667%;
  }

  .atom-col-offset-sm-12 {
    margin-left: 100%;
  }
}

/* White space classes */
.white-space-pre {
  white-space: pre !important;
}

.white-space-wrap {
  white-space: wrap !important;
}

.input-group {
  ::ng-deep mat-label{
      font-weight: 500px !important;
  }
}